import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// import VConsole from 'vconsole'
import i18n from './language/index'

import router from './router'

// const vConsole = new VConsole();
const app = createApp(App)
app.use(ElementPlus)
app.use(router)
app.use(i18n)

app.mount('#app')
