// zh.js
export default {
    'i18n.信诚国际': '信诚国际',
    'i18n.订单支付': '订单支付',
    'i18n.订单信息': '💌订单信息',
    'i18n.订单编号': '📝订单编号:',
    'i18n.支付金额': '💰支付金额:',
    'i18n.创建时间': '⏰创建时间:',
    'i18n.请选择': '请选择您的支付公链🌐.',
    'i18n.仅支持': '✔️Tron 仅支持 usdt/trx,其他公链支持 usdt/usdc 等',
    'i18n.Connect': '连接钱包',
    'i18n.Chain': '多链连接',
    'i18n.ConnectEnv': '连接成功',
    'i18n.TRON': 'USDT',
    'i18n.语言': '语言',
    'i18n.中文': '中文',
    'i18n.英文': '英文',
    'i18n.快捷支付': '快捷支付',
    'i18n.返回': '返回',
    'i18n.请选择': '请选择',
    'i18n.支付': '支付',
    'i18n.请安装钱包': '请安装钱包',
    'i18n.复制支付链接':'复制支付链接',
    'i18n.startFromHere': '从这里开始',
    
}