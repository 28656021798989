// zh.js
export default {
    'i18n.信诚国际': 'Fidelity International',
    'i18n.订单支付': 'Payment',
    'i18n.订单信息': '💌Order info',
    'i18n.订单编号': '📝订单编号:',
    'i18n.支付金额': '💰支付金额:',
    'i18n.创建时间': '⏰创建时间:',
    'i18n.请选择': '请选择您的支付公链🌐.',
    'i18n.仅支持': '✔️Tron 仅支持 usdt/trx,其他公链支持 usdt/usdc 等',
    'i18n.Connect': 'Connect Wallet',
    'i18n.Chain': 'Multi-chain',
    'i18n.ConnectEnv': 'Connect Success',
    'i18n.TRON': 'USDT',
    'i18n.语言': 'language',
    'i18n.中文': 'Chinese',
    'i18n.英文': 'English',
    'i18n.快捷支付': 'Fast payment',
    'i18n.返回': 'Back',
    'i18n.请选择': 'Please choose',
    'i18n.支付': 'Pay',
    'i18n.请安装钱包': 'Please install wallet',
    'i18n.复制支付链接':'Copy payment link'
}