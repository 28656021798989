// import { createWebHistory, createRouter } from "vue-router";

// const routes = [
//   {
//     path: '/',
//     component: () => import('../views/index.vue')
//   }
// ]

// const router = createRouter({
//   history: createWebHistory(), // 路由类型
//   routes // short for `routes: routes`
// })


// export default router


import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: '/',
    component: () => import('../views/index.vue')
  }
]

const router = createRouter({
  // 将 createWebHistory() 中的路径设置为你的二级目录
  // history: createWebHistory('/ko/'), 
  history: createWebHistory(),
  routes // routes 的简写形式
})

export default router
